export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const salesMenuPages = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
	profile: {
		id: 'employee',
		text: 'Employee',
		path: 'admin/profile',
		hide: true,
	},
	dashboard: {
		id: 'dashboard-sales',
		text: 'Dashboard',
		path: '/dashboard-sales',
		icon: 'Dashboard',
		subMenu: null,
	},
	register: {
		id: 'service-provider-registration',
		text: 'Register',
		path: 'sales-page/register-provider',
		icon: 'PersonPlusFill',
		subMenu: null,
	},
};

export const financeMenu = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
	profile: {
		id: 'employee',
		text: 'Employee',
		path: 'admin/profile',
		hide: true,
	},
	transactionList: {
		id: 'transactionList',
		text: 'Transactions',
		path: '/transactions',
		icon: 'CashCoin',
	},
};

export const teamLeaderPages = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
	// appointment: {
	// 	id: 'appointment',
	// 	text: 'Appointment',
	// 	path: 'appointment',
	// 	icon: 'Today',
	// 	subMenu: {
	dashboard: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
	},
	calendar: {
		id: 'calendar',
		text: 'Calendar',
		path: 'appointment/calendar',
		icon: 'EditCalendar',
		notification: true,
	},
	employeeList: {
		id: 'employeeList',
		text: 'Service Providers',
		path: 'service-providers',
		icon: 'PersonSearch',
	},
	appointmentList: {
		id: 'appointmentList',
		text: 'Appointment List',
		path: 'appointment/appointment-list',
		icon: 'Event',
	},
	employeeID: {
		id: 'employeeID',
		text: 'employeeID',
		path: 'service-provider',
		hide: true,
	},
	admins: {
		id: 'admins',
		text: 'Admins',
		path: 'admins',
		icon: 'ViewList',
		subMenu: {	
			salesList: {
				id: 'salesPageList',
				text: 'Sales Agents List',
				path: 'sales-page/sales-list',
				icon: 'PersonSearch',
			},
			addSales: {
				id: 'addSales',
				text: 'New Sales Agent',
				path: 'sales-page/new-sales',
				hide: true,
			},
			suspendedList: {
				id: 'suspendedList',
				text: 'Suspended',
				path: 'suspended/suspended-list',
				icon: 'PersonDashFill',
			},
		}
	},
	profile: {
		id: 'employee',
		text: 'Employee',
		path: 'admin/profile',
		hide: true,
	},
};

export const supervisorPages = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
	// appointment: {
	// 	id: 'appointment',
	// 	text: 'Appointment',
	// 	path: 'appointment',
	// 	icon: 'Today',
	// 	subMenu: {
	dashboard: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
	},
	calendar: {
		id: 'calendar',
		text: 'Calendar',
		path: 'appointment/calendar',
		icon: 'EditCalendar',
		notification: true,
	},
	employeeList: {
		id: 'employeeList',
		text: 'Service Providers',
		path: 'service-providers',
		icon: 'PersonSearch',
	},
	appointmentList: {
		id: 'appointmentList',
		text: 'Appointment List',
		path: 'appointment/appointment-list',
		icon: 'Event',
	},
	employeeID: {
		id: 'employeeID',
		text: 'employeeID',
		path: 'service-provider',
		hide: true,
	},
	admins: {
		id: 'admins',
		text: 'Admins',
		path: 'admins',
		icon: 'ViewList',
		subMenu: {	
			salesList: {
				id: 'salesPageList',
				text: 'Sales Agents List',
				path: 'sales-page/sales-list',
				icon: 'PersonSearch',
			},
			addSales: {
				id: 'addSales',
				text: 'New Sales Agent',
				path: 'sales-page/new-sales',
				hide: true,
			},
			addTeamLeader: {
				id: 'addTeamLeader',
				text: 'New Team Leader',
				path: 'team-leader/new-team-leader',
				hide: true,
			},
			teamLeaders: {
				id: 'teamLeaders',
				text: 'Team Leader List',
				path: 'team-leader/team-leader-list',
				icon: 'PersonSearch',
			},
			suspendedList: {
				id: 'suspendedList',
				text: 'Suspended',
				path: 'suspended/suspended-list',
				icon: 'PersonDashFill',
			},
		}
	},
	profile: {
		id: 'employee',
		text: 'Employee',
		path: 'admin/profile',
		hide: true,
	}
};

export const menuPages = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
	// appointment: {
	// 	id: 'appointment',
	// 	text: 'Appointment',
	// 	path: 'appointment',
	// 	icon: 'Today',
	// 	subMenu: {
	dashboard: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
	},
	calendar: {
		id: 'calendar',
		text: 'Calendar',
		path: 'appointment/calendar',
		icon: 'EditCalendar',
		notification: true,
	},
	employeeList: {
		id: 'employeeList',
		text: 'Service Providers',
		path: 'service-providers',
		icon: 'PersonSearch',
	},
	appointmentList: {
		id: 'appointmentList',
		text: 'Appointment List',
		path: 'appointment/appointment-list',
		icon: 'Event',
	},
	employeeID: {
		id: 'employeeID',
		text: 'employeeID',
		path: 'service-provider',
		hide: true,
	},
	profile: {
		id: 'employee',
		text: 'Employee',
		path: 'admin/profile',
		hide: true,
	},
	// },
	// },
	// crm: {
	// 	id: 'crm',
	// 	text: 'CRM',
	// 	path: 'crm',
	// 	icon: 'Contacts',
	// 	subMenu: {
	// 		dashboard: {
	// 			id: 'dashboard',
	// 			text: 'CRM Dashboard',
	// 			path: 'crm/dashboard',
	// 			icon: 'RecentActors',
	// 		},
	// 		customersList: {
	// 			id: 'customersList',
	// 			text: 'Customers',
	// 			path: 'crm/customers',
	// 			icon: 'PersonSearch',
	// 		},
	// 		customerID: {
	// 			id: 'customerID',
	// 			text: 'customerID',
	// 			path: 'crm/customer',
	// 			hide: true,
	// 		},
	// 	},
	// },
};
export const demoPages = {
	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	appointment: {
		id: 'appointment',
		text: 'Appointment',
		path: 'appointment',
		icon: 'Today',
		subMenu: {
			dashboard: {
				id: 'dashboard-booking',
				text: 'Dashboard Booking',
				path: 'dashboard-booking',
				icon: 'emoji_transportation',
				subMenu: null,
			},
			calendar: {
				id: 'calendar',
				text: 'Calendar',
				path: 'appointment/calendar',
				icon: 'EditCalendar',
				notification: true,
			},
			employeeList: {
				id: 'service-providers',
				text: 'Service Providers',
				path: 'service-providers',
				icon: 'PersonSearch',
			},
			employeeID: {
				id: 'employeeID',
				text: 'employeeID',
				path: 'service-provider',
				hide: true,
			},
			profile: {
				id: 'employee',
				text: 'Employee',
				path: 'admin/profile',
				hide: true,
			},
			appointmentList: {
				id: 'appointmentList',
				text: 'Appointment List',
				path: 'appointment/appointment-list',
				icon: 'Event',
			},
			transactionList: {
				id: 'transactionList',
				text: 'Transaction List',
				path: '/transactions',
				icon: 'CashCoin',
			}
		}
	},
	admins: {
		id: 'admins',
		text: 'Admins',
		path: 'admins',
		icon: 'ViewList',
		subMenu: {
			superAdmin: {
				id: 'superAdminList',
				text: 'Super Admins List',
				path: 'super-admin/employee-list',
				icon: 'PersonSearch',
			},
			callCenter: {
				id: 'callCenterList',
				text: 'Call Center List',
				path: 'call-center/employee-list',
				icon: 'PersonSearch',
			},
			callCenterID: {
				id: 'callCenterID',
				text: 'employeeID',
				path: 'call-center/employee',
				hide: true,
			},
			addCallCenter: {
				id: 'addCallCenter',
				text: 'New Call Center',
				path: 'call-center/new-employee',
				hide: true,
			},
			profile: {
				id: 'employee',
				text: 'Employee',
				path: 'admin/profile',
				hide: true,
			},
			salesList: {
				id: 'salesPageList',
				text: 'Sales Agents List',
				path: 'sales-page/sales-list',
				icon: 'PersonSearch',
			},
			addSales: {
				id: 'addSales',
				text: 'New Sales Agent',
				path: 'sales-page/new-sales',
				hide: true,
			},
			financeList: {
				id: 'financePageList',
				text: 'Accountant List',
				path: 'finance-page/accountant-list',
				icon: 'PersonSearch',
			},
			addFinance: {
				id: 'addFinance',
				text: 'New Finance User',
				path: 'finance-page/new-accountant',
				hide: true,
			},
			addSuperAdmin: {
				id: 'addSuperAdmin',
				text: 'New Admin User',
				path: 'super-admin/new-admin',
				hide: true,
			},
			addTeamLeader: {
				id: 'addTeamLeader',
				text: 'New Team Leader',
				path: 'team-leader/new-team-leader',
				hide: true,
			},
			teamLeaders: {
				id: 'teamLeaders',
				text: 'Team Leader List',
				path: 'team-leader/team-leader-list',
				icon: 'PersonSearch',
			},
			addSupervisor: {
				id: 'addSupervisor',
				text: 'New Supervisor',
				path: 'supervisor/new-supervisor',
				hide: true,
			},
			supervisorList: {
				id: 'supervisorList',
				text: 'Supervisors List',
				path: 'supervisor/supervisor-list',
				icon: 'PersonSearch',
			},
			suspendedList: {
				id: 'suspendedList',
				text: 'Suspended',
				path: 'suspended/suspended-list',
				icon: 'PersonDashFill',
			},
		},
	},
	performances: {
		id: 'performances',
		text: 'Performances',
		path: 'performances',
		icon: 'BarChartFill',
		subMenu: {
			promoCodesList: {
				id: 'promoCodesList',
				text: 'Promocodes List',
				path: '/promocodes',
				icon: 'FileCode',
			},
			teamLeaderList: {
				id: 'teamLeaderList',
				text: 'Team Leaders',
				path: '/teamleaders',
				icon: 'Microsoft',
			}
		},
	},
	crm: {
		id: 'crm',
		text: 'CRM',
		path: 'crm',
		icon: 'Contacts',
		subMenu: {
			dashboard: {
				id: 'dashboard',
				text: 'CRM Dashboard',
				path: 'crm/dashboard',
				icon: 'RecentActors',
			},
			customersList: {
				id: 'customersList',
				text: 'Customers',
				path: 'crm/customers',
				icon: 'PersonSearch',
			},
			customerID: {
				id: 'customerID',
				text: 'customerID',
				path: 'crm/customer',
				hide: true,
			},
		},
	},
	gridPages: {
		id: 'gridPages',
		text: 'Categories',
		path: 'grid-pages',
		icon: 'Window',
		subMenu: {
			gridFluid: {
				id: 'gridFluid',
				text: 'Category Lists',
				path: 'categories',
				icon: 'ViewDay',
			},
		},
	},
	auth: {
		id: 'auth',
		text: '',
		hide: true,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		hide: true,
	},
};
export const componentsMenu = {
	// id: 'components',
};
export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
