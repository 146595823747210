import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { demoPages } from '../menu';

const useRequest = (token, endPoint, method = 'get') => {
	const navigate = useNavigate();

	return async (additionalHeaders = {}, body = {}, customURL = endPoint) => {
		let data = null;
		let error = null;

		try {
			const headers = { Authorization: `Bearer ${token}` };
			if (body instanceof FormData) {
				headers['Content-Type'] = 'multipart/form-data';
			}

			const result = await axios({
				url: `${process.env.REACT_APP_API_URL}${customURL}`,
				method,
				headers: { ...headers, ...additionalHeaders },
				data: body,
			});
			data = result.data.data;
		} catch (err) {
			if (err.response.status === 401) {
				localStorage.removeItem('user')
				navigate(demoPages.login.path);
			}
			if (err?.response?.data?.message) error = err.response.data.message;
			else error = 'Unable to connect to the server.';
		}

		return [data, error];
	};
};

export default useRequest;
