import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import axios from 'axios';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import showNotification from '../../../components/extras/showNotification';

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mb-3'>Alen Home Solution</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = () => {
	const [usernameInput, setUsernameInput] = useState(false);

	const [phoneInput, setPhoneInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const handleChangePhone = (e) => {
		setPhoneInput(e.target.value);
	};
	const handleChangePassword = (e) => {
		setPasswordInput(e.target.value);
	};

	const navigate = useNavigate();
	const handleOnClick = async () => {
		try {
			const data = {
				phoneNumber: phoneInput,
				password: passwordInput,
			};
			await axios
				.post(`${process.env.REACT_APP_API_URL}/admin/login`, data)
				.then((res) => {
					localStorage.setItem('user', JSON.stringify(res.data));
					window.location.reload().then(() => {
						if (res.data.data.admin.role === 'Call-Center') {
							return navigate('/dashboard-booking');
						}
						return navigate('/dashboard');
					});
				})
				.catch((err) => {
					setErrorMessage(err.response.data.message);
					showNotification('error', err.response.data.message);
				});
		} catch (err) {
			console.error(err.message);
		}
	};
	useEffect(() => {
		if (localStorage.getItem('user')) {
			if (JSON.parse(localStorage.getItem('user')).data?.admin?.role === 'Call-Center' || 
				JSON.parse(localStorage.getItem('user')).data?.admin?.role === 'Team-Leader' ||
				JSON.parse(localStorage.getItem('user')).data?.admin?.role === 'Supervisor' ) 
				return navigate('/dashboard-booking');	
			if (JSON.parse(localStorage.getItem('user')).data?.admin?.role === 'Sales')
				return navigate('/dashboard-sales');
			if (JSON.parse(localStorage.getItem('user')).data?.admin?.role === 'Finance')
				return navigate('/transactions');
		    return navigate('/dashboard');
		}
		return null;
	});
	return (
		<PageWrapper
			title='Login'
			className={classNames({ 'themed-background': !false, 'bg-info': !!false })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								{usernameInput && (
									<div className='align-left'>
										<Button
											color='info'
											isLink
											icon='ArrowBack'
											onClick={() => setUsernameInput(false)}>
											Back
										</Button>
									</div>
								)}
								<div className='text-center my-5'>
									<h3>Welcome</h3>
								</div>

								<LoginHeader isNewUser={false} />
								{/* show error message */}
								{errorMessage && (
									<div>
										<h5 style={{ color: 'red' }}>**{errorMessage}**</h5>
									</div>
								)}
								<form className='row g-4' onSubmit={(e) => e.preventDefault()}>
									<div className='col-12'>
										{!usernameInput ? (
											<FormGroup
												id='phone'
												name='phone'
												isFloating
												label='Your Phone number'>
												<Input
													type='tel'
													autoComplete='phone'
													value={phoneInput}
													required
													onChange={handleChangePhone}
												/>
											</FormGroup>
										) : (
											<FormGroup
												id='password'
												name='password'
												isFloating
												label='Password'>
												<Input
													type='password'
													value={passwordInput}
													onChange={handleChangePassword}
													// icon={SvgEyeFill}
												/>
											</FormGroup>
										)}
									</div>
									<div className='col-12'>
										{!usernameInput ? (
											<Button
												color='warning'
												className='w-100 py-3 login-button '
												onClick={(e) => {
													e.preventDefault();
													setUsernameInput(true);
												}}>
												Continue
											</Button>
										) : (
											<Button
												type='submit'
												color='warning'
												className='w-100 py-3 login-button '
												onClick={() => {
													handleOnClick();
												}}>
												Login
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Login.defaultProps = {
	isSignUp: false,
};

export default Login;
