import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const AppointmentContext = createContext();

export const AppointmentContextProvider = ({ children }) => {
	const [appointments, setAppointments] = useState([]);

	const sharedState = useMemo(
		() => ({
			appointments,
			setAppointments,
		}),
		[appointments, setAppointments],
	);

	return (
		<AppointmentContext.Provider value={sharedState}>{children}</AppointmentContext.Provider>
	);
};

AppointmentContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export function useAppointmentContext() {
	return useContext(AppointmentContext);
}
