import React, { lazy } from 'react';
import { dashboardMenu, demoPages } from '../menu';
import Login from '../pages/presentation/auth/Login';
import UpcomingEvents from '../pages/common/upcomingEvents';
import ExportSP from '../pages/presentation/appointment/ExportSP';
import SalesPage from '../pages/presentation/admins/SalesPage'

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	DASHBOARD_BOOKING: lazy(() => import('../pages/dashboard/DashboardBookingPage')),
	SALES_DASHBOARD: lazy(() => import('../pages/presentation/admins/SalesDashboard')),
	SUMMARY: lazy(() => import('../pages/SummaryPage')),
};
// const GRID = {
// 	FLUID: lazy(() => import('../pages/presentation/Category/CategoryList')),
// };
const EDIT = {
	WIZARD: lazy(() => import('../pages/presentation/appointment/AddServiceProvider')),
	EDIT_SERVICE_PROVIDER: lazy(() =>
		import('../pages/presentation/appointment/EditServiceProvider'),
	),
	EDIT_ADMIN: lazy(() =>
		import('../pages/presentation/admins/EditAdmin'),
	),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const APP = {
	CATEGORY: {
		CATEGORY_LIST: lazy(() => import('../pages/presentation/Category/CategoryList')),
		CATEGORY_VIEW: lazy(() => import('../pages/presentation/Category/CategoryViewPage')),
		SUBCATEGORY: lazy(() => import('../pages/presentation/Category/SubCategoryPage')),
	},
	APPOINTMENT: {
		CALENDAR: lazy(() => import('../pages/presentation/appointment/CalendarPage')),
		SERVICE_PROVIDER_LIST: lazy(() =>
			import('../pages/presentation/appointment/ServiceProviderList'),
		),
		// CALL_CENTER: lazy(() => import('../pages/presentation/appointment/CallCenter')),
		// CALL_CENTER_VIEW: lazy(() => import('../pages/presentation/appointment/CallCenterPage')),
		SERVICE_PROVIDER_VIEW: lazy(() =>
			import('../pages/presentation/appointment/ServiceProviderViewPage'),
		),
		ADMIN_PROFILE: lazy(() => import('../pages/presentation/admin/AdminProfile')),
		APPOINTMENT_LIST: lazy(() => import('../pages/presentation/appointment/AppointmentList')),
		TRANSACTION_LIST: lazy(() => import('../pages/presentation/appointment/ExportTransaction')),
		// PROMO_CODES_LIST: lazy(() => import('../pages/presentation/appointment/PromoCodesList')),
		// TEAM_LEADER_LIST: lazy(() => import('../pages/presentation/appointment/TeamLeaders'))
		// ADD_CALL_CENTER: lazy(() => import('../pages/presentation/appointment/AddCallCenter')),
	},
	ADMINS: {
		CALL_CENTER: lazy(() => import('../pages/presentation/admins/CallCenter')),
		CALL_CENTER_VIEW: lazy(() => import('../pages/presentation/admins/CallCenterPage')),
		ADD_CALL_CENTER: lazy(() => import('../pages/presentation/admins/AddCallCenter')),
		SALES_PAGE: lazy(() => import('../pages/presentation/admins/SalesPage')),
		ADD_FINANCE: lazy(() => import('../pages/presentation/admins/AddFinance')),
		ADD_SALES: lazy(() => import('../pages/presentation/admins/AddSales')),
		ADD_SUPER_ADMIN: lazy(() => import('../pages/presentation/admins/AddSuperAdmin')),
		ADD_TEAM_LEADER: lazy(() => import('../pages/presentation/admins/AddTeamLeader')),
		ADD_SUPERVISOR: lazy(() => import('../pages/presentation/admins/AddSupervisor')),
	}, 
	PERFORMANCES: {
		PROMO_CODES_LIST: lazy(() => import('../pages/presentation/performances/PromoCodesList')),
		TEAM_LEADERS_LIST: lazy(() => import('../pages/presentation/performances/TeamLeaders')),
	},
	CRM: {
		CRM_DASHBOARD: lazy(() => import('../pages/presentation/crm/CrmDashboard')),
		CUSTOMERS: lazy(() => import('../pages/presentation/crm/CustomersList')),
		CUSTOMER: lazy(() => import('../pages/presentation/crm/Customer')),
	},
};
const presentation = [
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: 'dashboard-booking',
		element: <LANDING.DASHBOARD_BOOKING />,
		exact: true,
	},
	{
		path: 'service-provider/add-provider',
		element: <EDIT.WIZARD />,
		exact: true,
	},
	{
		path: 'service-provider/edit-provider/:id',
		element: <EDIT.EDIT_SERVICE_PROVIDER />,
		exact: true,
	},
	{
		path: 'admins/edit-admin/:id',
		element: <EDIT.EDIT_ADMIN />,
		exact: true,
	},
	{
		path: 'auth-pages/404',
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.gridPages.subMenu.gridFluid.path,
		element: <APP.CATEGORY.CATEGORY_LIST />,
		exact: true,
	},
	{
		path: `categories/:id`,
		element: <APP.CATEGORY.CATEGORY_VIEW />,
		exact: true,
	},
	{
		path: `subcategories/:subId`,
		element: <APP.CATEGORY.SUBCATEGORY />,
		exact: true,
	},
	{
		path: demoPages.appointment.subMenu.calendar.path,
		element: <APP.APPOINTMENT.CALENDAR />,
		exact: true,
	},
	{
		path: demoPages.appointment.subMenu.employeeList.path,
		element: <APP.APPOINTMENT.SERVICE_PROVIDER_LIST />,
		exact: true,
	},
	{
		path: demoPages.admins.subMenu.callCenter.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,
	},
	{
		path: `${demoPages.admins.subMenu.callCenterID.path}/:id`,
		element: <APP.ADMINS.CALL_CENTER_VIEW />,
		exact: true,
	},
	{
		path: `${demoPages.appointment.subMenu.employeeID.path}/:id`,
		element: <APP.APPOINTMENT.SERVICE_PROVIDER_VIEW />,
		exact: true,
	},
	{
		path: `${demoPages.appointment.subMenu.profile.path}`,
		element: <APP.APPOINTMENT.ADMIN_PROFILE />,
		exact: true,
	},
	{
		path: demoPages.appointment.subMenu.appointmentList.path,
		element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
		exact: true,
	},
	{
		path: demoPages.admins.subMenu.addCallCenter.path,
		element: <APP.ADMINS.ADD_CALL_CENTER />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.dashboard.path,
		element: <APP.CRM.CRM_DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.customersList.path,
		element: <APP.CRM.CUSTOMERS />,
		exact: true,
	},
	{
		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
		element: <APP.CRM.CUSTOMER />,
		exact: true,
	},
	{
		path: `/Export`,
		element: <UpcomingEvents />,
		exact: true,
	},
	{
		path: `/transactions`,
		element: <APP.APPOINTMENT.TRANSACTION_LIST />,
		exact: true,
	},
	{
		path: `/service-provider/export`,
		element: <ExportSP />,
		exact: true,
	},
	{
		path: `/dashboard-sales`,
		element: <LANDING.SALES_DASHBOARD />,
		exact: true,	
	},
	{
		path: `/sales-page/register-provider`,
		element: <SalesPage />,
		exact: true,	
	},
	{
		path: `${demoPages.admins.subMenu.callCenterID.path}/:id`,
		element: <APP.ADMINS.CALL_CENTER_VIEW />,
		exact: true,
	},
	{
		path: demoPages.admins.subMenu.superAdmin.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.callCenter.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.salesList.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.financeList.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.addFinance.path,
		element: <APP.ADMINS.ADD_FINANCE />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.addSales.path,
		element: <APP.ADMINS.ADD_SALES />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.addSuperAdmin.path,
		element: <APP.ADMINS.ADD_SUPER_ADMIN />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.addTeamLeader.path,
		element: <APP.ADMINS.ADD_TEAM_LEADER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.teamLeaders.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.addSupervisor.path,
		element: <APP.ADMINS.ADD_SUPERVISOR />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.supervisorList.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.admins.subMenu.suspendedList.path,
		element: <APP.ADMINS.CALL_CENTER />,
		exact: true,	
	},
	{
		path: demoPages.performances.subMenu.promoCodesList.path,
		element: <APP.PERFORMANCES.PROMO_CODES_LIST />,
		exact: true,
	},
	{
		path: demoPages.performances.subMenu.teamLeaderList.path,
		element: <APP.PERFORMANCES.TEAM_LEADERS_LIST />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
