import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
	// const [theme, setTheme] = useState('light');

	// const changeTheme = useCallback(() => {
	// 	if (theme === 'dark') setTheme('light');
	// 	else setTheme('dark');
	// }, [theme]);

	// const sharedState = useMemo(
	// 	() => ({
	// 		theme,
	// 		changeTheme,
	// 	}),
	// 	[theme, changeTheme],
	// );

	// get user from local storage
	const user = JSON.parse(localStorage.getItem('user'));
	const [userState] = useState(user);

	const userInfo = useMemo(() => {
		if (userState) return userState?.data?.admin;
		return '';
	}, [userState]);
	const [currentUser, setCurrentUser] = useState({
		userInfo,
	});
	// get token from local storage
	const [token] = useState(user ? user?.token : null);
	const [tokenState, setTokenState] = useState(token);

	const sharedState = useMemo(
		() => ({
			currentUser,
			setCurrentUser,
			tokenState,
			setTokenState,
		}),
		[currentUser, setCurrentUser, tokenState, setTokenState],
	);

	return <UserContext.Provider value={sharedState}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export function useAppContext() {
	return useContext(UserContext);
}
