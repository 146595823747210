import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import { demoPages } from '../../menu';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
	const isUserLoggedIn = !!localStorage.getItem('user');
	const loginPath = demoPages.login.path;

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname !== loginPath && !isUserLoggedIn)
			navigate(loginPath, { replace: true });
	}, [location.pathname, isUserLoggedIn, loginPath, navigate])

	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}

			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
