import React, { useEffect, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';

import { useFormik } from 'formik';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { demoPages } from '../../../menu';

import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import useRequest from '../../../hooks/useRequest';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
// import Badge from '../../../components/bootstrap/Badge';

import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';

import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
// import PaginationButtons, {
// 	dataPagination,
// 	PER_COUNT,
// } from '../../../components/PaginationButtons';
import { useAppContext } from '../../../contexts/userContext';

// eslint-disable-next-line react/prop-types
const ExportSP = ( ) => {
	const [filterMenu, setFilterMenu] = useState(false);
	const [providers, setProviders] = useState([]);
	const [categories, setCategories] = useState([]);
	const { tokenState } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	const getServiceProvider = useRequest(tokenState, `/serviceprovider`);

	const getSubcategory = useRequest(tokenState, `/categories`);
	const getsubCategory = async () => {
		const [data, error] = await getSubcategory();

		if (data) {
			setCategories(data.categories);
		} else {
			console.log(error);
		}
	};
	useEffect(() => {
		getsubCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// This is a function that is used to fetch service providers
	const getProvider = async () => {
		const [data, error] = await getServiceProvider();

		if (data) {
			setProviders(data.serviceProviders);
			setIsLoading(false);
			// console.log(data.serviceProviders);
		} else {
			console.log(error);
		}
	};
	useEffect(() => {
		getProvider();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			statusInput: '',
			approvalStatus: '',
			categoryInput: '',
			status: '',
			gender: '',
			credit: '',
			from: '',
			upto: '',
			operator: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setFilterMenu(false);
			// alert(JSON.stringify(values, null, 2));
		},
	});
	// this is a function that is used to fetch subcatagories

	let searchUsers = providers?.filter(
		(provider) =>
			provider.user.firstName
				.toLowerCase()
				.includes(formik.values.searchInput.toLowerCase()) ||
			provider.user.lastName
				.toLowerCase()
				.includes(formik.values.searchInput.toLowerCase()) ||
			`${provider.user.firstName} ${provider.user.lastName}`
				.trim()
				.toLowerCase()
				.includes(formik.values.searchInput.toLowerCase().trim()) ||
			provider.user.phoneNumber.trim().includes(formik.values.searchInput.trim()),
	);
	searchUsers = searchUsers?.filter((provider) => {
		if (formik.values.approvalStatus !== '')
			return provider.approvalStatus
				.toLowerCase()
				.includes(formik.values.approvalStatus.toLowerCase());
		return 1;
	});
	searchUsers = searchUsers?.filter((provider) => {
		if (formik.values.status !== '')
			return provider.user.status.toLowerCase() === formik.values.status.toLowerCase();
		return 1;
	});
	searchUsers = searchUsers?.filter((provider) =>
		provider.categories.some((service) =>
			service.name
				.trim()
				.toLowerCase()
				.includes(formik.values.categoryInput.trim().toLowerCase()),
		),
	);
	searchUsers = searchUsers?.filter((provider) => {
		if (formik.values.gender !== '')
			return provider.gender.toLowerCase() === formik.values.gender.toLowerCase();
		return 1;
	});
	searchUsers = searchUsers?.filter((provider) => {
		if (formik.values.credit !== '') {
			if (formik.values.operator === 'GTE') return provider.credit >= formik.values.credit;
			if (formik.values.operator === 'LTE') return provider.credit <= formik.values.credit;
			return provider.credit === formik.values.credit;
		}
		return provider.credit >= formik.values.credit;
	});
	if (moment(formik.values.from).format('D') !== 'Invalid date') {
		searchUsers = searchUsers?.filter(
			(filteredData) =>
				moment(filteredData.createdAt).diff(moment(formik.values.from), 'days', true) >= 0,
		);
	}
	if (moment(formik.values.upto).format('D') !== 'Invalid date') {
		searchUsers = searchUsers?.filter(
			(filteredData) =>
				moment(filteredData.createdAt).diff(moment(formik.values.upto), 'days') <= 0,
			true,
		);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
	// useEffect(() => {
	// 	const totalItems = searchUsers.length;
	// 	const totalPage = Math.ceil(totalItems / perPage);
	// 	if (currentPage > totalPage) {
	// 		setCurrentPage(1);
	// 	}
	// });
	if (isLoading) {
		return (
			<div className='absolute m-auto left-0 right-0'>
				<div className='spinner-border' role='status'>
					<span className='sr-only'>.</span>
				</div>
			</div>
		);
	}

	const filterColumns = ({ field, columns, currentFilters }) => {
		// remove already filtered fields from list of columns
		const filteredFields = currentFilters?.map((item) => item.field);
		return columns
			.filter(
				(colDef) =>
					colDef.filterable &&
					(colDef.field === field || !filteredFields.includes(colDef.field)),
			)
			.map((column) => column.field);
	};

	const getColumnForNewFilter = ({ currentFilters, columns }) => {
		const filteredFields = currentFilters?.map(({ field }) => field);
		const columnForNewFilter = columns
			.filter((colDef) => colDef.filterable && !filteredFields.includes(colDef.field))
			.find((colDef) => colDef.filterOperators?.length);
		return columnForNewFilter?.field ?? null;
	};

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			valueGetter: (params) =>
				`${params.row.user.firstName} ${params.row.user.lastName}`,
			width: 200,
		},
		{
			field: 'phoneNumber',
			headerName: 'Phone',
			valueGetter: (params) => params.row.user.phoneNumber,
			width: 150,
		},
		{
			field: 'email',
			headerName: 'Email',
			valueGetter: (params) => params.row.user.email,
			width: 200,
		},
		{
			field: 'role',
			headerName: 'Role',
			valueGetter: (params) => params.row.user.role,
			width: 150,
		},
		{
			field: 'gender',
			headerName: 'Gender',
			valueGetter: (params) => params.row.gender,
			width: 120,
		},
		{
			field: 'credit',
			headerName: 'Credit',
			valueGetter: (params) => Math.round(params.row.credit),
			width: 120,
		},
		{
			field: 'status',
			headerName: 'Status',
			valueGetter: (params) => params.row.user.status,
			width: 100,
		},
		{
			field: 'approvalStatus',
			headerName: 'Approval Status',
			valueGetter: (params) => params.row.approvalStatus,
			width: 120,
		},
		{
			field: 'registeredAt',
			headerName: 'Registered At',
			valueGetter: (params) => params.row.createdAt?.toString().slice('T', 10),
			width: 120,
		},
	];
	
	return (
		<PageWrapper title={demoPages.appointment.subMenu.appointmentList.icontext}>
			<Page>
				<div width="100%">
					<Card>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle>Service Providers</CardTitle>
					</CardLabel>
				</CardHeader>
				<SubHeader>
					<SubHeaderLeft>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<Icon icon='Search' size='2x' color='primary' />
						</label>
						<Input
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent'
							placeholder='Search name'
							onChange={formik.handleChange}
							value={formik.values.searchInput}
						/>
					</SubHeaderLeft>
					<SubHeaderRight>
						<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
							<DropdownToggle hasIcon={false}>
								<Button icon='FilterAlt' color='primary' isLight />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
								<div className='container py-2'>
									<form className='row g-3' onSubmit={formik.handleSubmit}>
										<div className='col-12'>
											<FormGroup>
												<Label htmlFor='status'>Status</Label>
												<Input
													id='status'
													name='status'
													ariaLabel='name'
													placeholder='Service Provider Status'
													list={['Active', 'Inactive']}
													onChange={formik.handleChange}
													value={formik.values.status}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup>
												<Label htmlFor='approvalStatus'>
													Approval Status
												</Label>
												<Input
													id='approvalStatus'
													name='approvalStatus'
													ariaLabel='name'
													placeholder='Service Provider Status'
													list={['Approved', 'Pending', 'Declined']}
													onChange={formik.handleChange}
													value={formik.values.approvalStatus}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup>
												<Label htmlFor='categoryInput'>Category</Label>
												<Input
													id='categoryInput'
													name='categoryInput'
													ariaLabel='name'
													placeholder='Category name'
													list={[
														...Object.keys(categories).map(
															(u) => `${categories[u]?.name} `,
														),
													]}
													onChange={formik.handleChange}
													value={formik.values.categoryInput}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup>
												<Label htmlFor='credit'>Credit Balance</Label>
												<Input
													id='credit'
													type='number'
													name='credit'
													ariaLabel='name'
													placeholder='Credit Balance'
													onChange={formik.handleChange}
													value={formik.values.credit}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup>
												<Label htmlFor='gender'>Gender</Label>
												<Input
													id='gender'
													name='gender'
													ariaLabel='gender'
													placeholder='Gender'
													list={['Male', 'Female']}
													onChange={formik.handleChange}
													value={formik.values.gender}
												/>
											</FormGroup>
										</div>
										<div className='row g-3'>
											<Label htmlFor='filterByDate'>Filter by date</Label>
											<div className='col-12'>
												<FormGroup id='from' label='From'>
													<Input
														type='date'
														value={moment(formik.values.from)
															.format(moment.HTML5_FMT.DATE)
															.toString()}
														onChange={formik.handleChange}
														max={
															formik.values.upto
																.toString()
																.split('T')[0]
														}
													/>
												</FormGroup>
											</div>

											{formik.values.from.length > 0 && (
												<div className='col-12'>
													<FormGroup id='upto' label='Up to'>
														<Input
															type='date'
															value={moment(formik.values.upto)
																.format(moment.HTML5_FMT.DATE)
																.toString()}
															onChange={formik.handleChange}
															min={
																formik.values.from
																	.toString()
																	.split('T')[0]
															}
														/>
													</FormGroup>
												</div>
											)}
										</div>
										<div className='col-6'>
											<Button
												color='primary'
												isOutline
												className='w-100'
												onClick={() => {
													formik.setFieldValue('statusInput', '');
													formik.setFieldValue('approvalStatus', '');
													formik.setFieldValue('categoryInput');
													formik.setFieldValue('gender', '');
													formik.setFieldValue('credit', '');
													formik.setFieldValue('upto', '');
													formik.setFieldValue('from', '');
												}}>
												Reset
											</Button>
										</div>
										<div className='col-6'>
											<Button color='primary' className='w-100' type='submit'>
												Filter
											</Button>
										</div>
									</form>
								</div>
							</DropdownMenu>
						</Dropdown>
					</SubHeaderRight>
				</SubHeader>
				{/* <CardBody className="table-responsive"> */}
					<div className='row h-100'>
						<div className='col-12'>
							{/* <Card > */}
								<Box
									sx={{
										height: 515,
										width: '100%',
										'& .super-app-theme': {
											backgroundColor: 'white',
											borderRadius: 'none',
											border: 'none',
											fontSize: '13px',
										},
									}}>
									<DataGrid
										components={{ Toolbar: GridToolbar }}
										rows={searchUsers}
										getRowId={(row) => row._id}
										columns={columns}
										pageSize={7}
										rowHeight={50}
										// checkboxSelection
										disableSelectionOnClick
										getRowClassName={() => `super-app-theme`}
										slotProps={{
											toolbar: {
												showQuickFilter: true,
											},
											filterPanel: {
												filterFormProps: {
													filterColumns,
												},
												getColumnForNewFilter,
											},
										}}
									/>
								</Box>
								{/* </Card> */}
							
						</div>
					</div>

					{/* <table id='tests-table-xls-button' className='table table-modern'>
						<thead>
							<tr>
								<th>Name </th>
								<th>Phone </th>
								<th>Email</th>
								<th>Role</th>
								<th>Gender</th>
								<th>Credit</th>
								<th>Status</th>
								<th>Approval Status</th>
								<th>Registered At</th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(searchUsers, currentPage, perPage).map(
								(item, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<tr key={index}>
										<td>
											<div className='d-flex align-items-center'>
												<span className='text-nowrap'>{`${item.user?.firstName} ${item.user?.lastName}`}</span>
											</div>
										</td>
										<td>
											<div className='d-flex align-items-center'>
												<span className='text-nowrap'>
													{item.user?.phoneNumber}
												</span>
											</div>
										</td>
										<td>
											<div>
												<div>{item?.callCenterName}</div>
												<div className='small text-muted'>
													{item.user?.email}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{item.user?.role}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{item.gender}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{Math.round(item.credit) || 0}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{item.user?.status}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{item.approvalStatus}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{item.createdAt.toString().slice(0, 10)}
												</div>
											</div>
										</td>
									</tr>
								),
							)}
						</tbody>
					</table> */}
					{/* </CardBody> */}
					</Card>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default React.memo(ExportSP);
