const validate = (values) => {
	const errors = {};
	if (!values.firstName) {
		errors.firstName = 'Required';
	} else if (values.firstName.length < 3) {
		errors.firstName = 'Must be 3 characters or more';
	} else if (values.firstName.length > 20) {
		errors.firstName = 'Must be 20 characters or less';
	}

	if (!values.lastName) {
		errors.lastName = 'Required';
	} else if (values.lastName.length < 3) {
		errors.lastName = 'Must be 3 characters or more';
	} else if (values.lastName.length > 20) {
		errors.lastName = 'Must be 20 characters or less';
	}

	if (!values.street) {
		errors.street = 'Required';
	} else if (values.street.length < 3) {
		errors.street = 'Must be 3 characters or more';
	} else if (values.street.length > 20) {
		errors.street = 'Must be 20 characters or less';
	}

	if (!values.wereda) {
		errors.wereda = 'Required';
	} else if (values.wereda <= 0) {
		errors.wereda = 'Must be above 0';
	}

	if (!values.workExperience) {
		errors.workExperience = 'Required';
	} else if (values.workExperience <= 0) {
		errors.workExperience = 'Must be above 0';
	}

	if (!values.displayName) {
		errors.displayName = 'Required';
	} else if (values.displayName.length > 30) {
		errors.displayName = 'Must be 30 characters or less';
	}

	if (!values.emailAddress) {
		errors.emailAddress = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
		errors.emailAddress = 'Invalid email address';
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = 'Required';
	} else if (values.phoneNumber.length !== 10 || !values.phoneNumber.match(/^[0-9]+$/i)) {
		errors.phoneNumber = 'Phone Number must be 10 numeric characters';
	}

	if (!values.location) {
		errors.location = 'Required';
	} else if (values.location.length > 130) {
		errors.location = 'Must be 130 characters or less';
	} else if (values.location.length < 3) {
		errors.location = 'Must be 3 characters or more';
	}

	if (values.promoCode && values.promoCode.length !== 5) {
  		errors.promoCode = 'Promo code must be 5 characters long';
	}

	if (values.birthDate) {
		const birthYear = new Date(values.birthDate).getFullYear();
		const age = new Date().getFullYear() - birthYear;
		if (age < 18) {
			errors.birthDate = 'Age must be 18 or higher';
		}
	}
	if (values.currentPassword) {
		if (!values.newPassword) {
			errors.newPassword = 'Please provide a valid password.';
		} else {
			errors.newPassword = '';

			if (values.newPassword.length < 8 || values.newPassword.length > 32) {
				errors.newPassword +=
					'The password must be at least 8 characters long, but no more than 32. ';
			}
			if (!/[0-9]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one digit appear anywhere in the string. ';
			}
			if (!/[a-z]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one lowercase letter appear anywhere in the string. ';
			}
			if (!/[A-Z]/g.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one uppercase letter appear anywhere in the string. ';
			}
			// eslint-disable-next-line no-useless-escape
			if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(values.newPassword)) {
				errors.newPassword +=
					'Require that at least one special character appear anywhere in the string. ';
			}
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = 'Please provide a valid password.';
		} else if (values.newPassword !== values.confirmPassword) {
			errors.confirmPassword = 'Passwords do not match.';
		}
	} else if (!values.currentPassword) {
		errors.currentPassword = 'Required';
		if (!values.newPassword && !values.confirmPassword) {
			errors.newPassword = 'Required';
			errors.confirmPassword = 'Required';
		} else if (!values.confirmPassword) {
			errors.confirmPassword = 'Required';
		} else if (!values.newPassword) {
			errors.newPassword = 'Required';
		}
	}
	return errors;
};

export default validate;
