const LANG = {
	EN: {
		text: 'English',
		lng: 'en-US',
		icon: 'CustomUsa',
	},
	AM: {
		text: 'Amharic',
		lng: 'am-AM',
		icon: 'CustomEthiopia',
	},

};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
