// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/self-closing-comp */
import React, { createContext, useState, useMemo } from 'react';

export const DashboardContext = createContext();

// eslint-disable-next-line prettier/prettier, react/prop-types
const DashboardContextProvider = ({ children }) => {
	const [appointments, setAppointments] = useState([]);
	const [spStatusData, setSpStatusData] = useState([]);
	const dashboardData = useMemo(
		() => ({ appointments, setAppointments, spStatusData, setSpStatusData }),
		[spStatusData, appointments],
	);
	return <DashboardContext.Provider value={dashboardData}>{children}</DashboardContext.Provider>;
};
export default DashboardContextProvider;
