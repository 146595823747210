// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Wizard, { WizardItem } from '../../../components/Wizard';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';

import Label from '../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import validate from '../demo-pages/helper/editPagesValidate';
import showNotification from '../../../components/extras/showNotification';
import { useAppContext } from '../../../contexts/userContext';

const AddServiceProvider = () => {
	const navigate = useNavigate();

	const TABS = {
		PERSONAL_INFO: 'Personal Info',
		ACCOUNT_DETAIL: 'Account Details',
		FILE: 'FILE',
	};
	const { tokenState } = useAppContext();
	const [, setActiveTab] = useState(TABS.PERSONAL_INFO);
	const [categories, setCategories] = useState();
	const [subcategory, setSubcategory] = useState([]);
	const [services, setServices] = useState([]);
	const [categorySelectedId, setCategorySelectedId] = useState();
	const [subcategorySelectedId, setSubcategorySelectedId] = useState([]);
	const [servicesSelectedId, setServicesSelectedId] = useState([]);
	const [supCertificateImage, setSupCertificateImage] = useState([]);
	const [eduCertificateImage, setEduCertificateImage] = useState([]);
	const [idImage, setIdImage] = useState([]);
	const [spId, setSpId] = useState();
	const [buttondisable, setButtondisable] = useState(false);
	const [isStepCompleted, setIsStepCompleted] = useState(false);

	const [profilePicture, setProfilePicture] = useState(null);

	const AddnewServiceProviderRequest = useRequest(tokenState, `/users/createsp`, 'post');
	const AddnewadditionalinfoRequest = useRequest(
		tokenState,
		`/serviceprovider/becomespbycallcenter`,
		'post',
	);

	// This is an API call for Fetching catagories
	const requestCategories = useRequest(tokenState, `/categories`);
	const getCategories = async () => {
		const [data, error] = await requestCategories();

		if (data) setCategories(data.categories);
		else console.log(error);
	};
	// This is an API call for Fetching subcatagories
	const getsubCategoryRequest = useRequest(
		tokenState,
		`/categories/${categorySelectedId}/subcategories?`,
	);
	const getsubCategory = async () => {
		setSubcategory([]);
		setSubcategorySelectedId([]);
		if (categorySelectedId) {
			const [data, error] = await getsubCategoryRequest();

			if (data) {
				setSubcategory(data.subcategories);
			} else {
				console.log(error);
			}
		}
	};
	useEffect(() => {
		getsubCategory();
	}, [categorySelectedId, tokenState]);

	// This is an API call for Fetching services
	useEffect(() => {
		const fetchServices = async () => {
			if (subcategorySelectedId.length === 0) setServicesSelectedId([]);
			setServices([]);
			setServicesSelectedId([]);
			await Promise.all(
				// eslint-disable-next-line array-callback-return
				subcategorySelectedId.map((subcategoryId) => {
					return axios
						.get(
							`${process.env.REACT_APP_API_URL}/subcategories/${subcategoryId}/services?`,
							{
								headers: {
									Authorization: `Bearer ${tokenState}`,
								},
							},
						)
						.then((res) => {
							setServices((state) => {
								const newServices = [];
								res.data.data.services.forEach((service) => {
									if (!state.find((item) => item._id === service._id)) {
										newServices.push(service);
									}
								});
								return [...state, ...newServices];
							});
						})
						.catch(() => {
							// console.log(err.response.data.message);
						});
				}),
			);
		};
		fetchServices();
	}, [subcategorySelectedId]);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			emailAddress: '',
			location: '',
			phoneNumber: '',
			gender: '',
			birthDate: '',
			promoCode: '',
			subcity: '',
			wereda: '',
			street: '',
			category: '',
			personalId: '',
			workExperience: '',
			identificationCardType: '',
			educationalStatus: '',
			image: null,
			eduImage: null,
			supImage: null,
		},
		validate,
	});
	const location = [
		'Addis Ketema',
		'Akaky Kaliti',
		'Arada',
		'Bole',
		'Gullele',
		'Kirkos',
		'Kolfe Keranio',
		'Lideta',
		'Nifas Silk-Lafto',
		'Yeka',
		'Lemi Kura',
	];
	const education = [
		'Elementary',
		'High School',
		'Preparatory',
		'Diploma',
		'Degree',
		'Certificate (Level 1)',
		'Certificate (Level 2)',
		'Certificate (Level 3)',
	];
	const gender = ['Male', 'Female'];
	const personalId = ['Government Id Card', "Driver's License Card", 'Passport Card'];
	useEffect(() => {
		getCategories();
	}, []);

	// This is a function that is used to Add  Basic-Info about employees
	const addBasicInfo = async (values, errors, isTouched) => {
		if (Object.keys(isTouched).length === 0) {
			showNotification('error', 'Please fill the form correctly');
			throw new Error('Unable to create user');
		} else if (
			errors.firstName ||
			errors.lastName ||
			errors.emailAddress ||
			errors.phoneNumber ||
			errors.location ||
			errors.promoCode
		) {
			showNotification(
				'error',
				`Please fill ${errors.firstName ? 'First name,' : ''} ${
					errors.lastName ? 'Last name,' : ''
				} ${errors.phoneNumber ? ' PhoneNumber,' : ''} ${
					errors.emailAddress ? 'Email address,' : ''
				} ${errors.location ? 'location' : ''} ${
					errors.promoCode ? ' Promo code' : ''}  correctly `,

			);
			throw new Error('Unable to create user');
		} else {
			let data = {};
			if (values.promoCode !== '') {
				data = {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					email: values.emailAddress,
					addressString: values.location,
					promoCode: values.promoCode,
				};
			} else {
				data = {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					email: values.emailAddress,
					addressString: values.location,
				};
			}

			const [data1, error] = await AddnewServiceProviderRequest({}, data);
			if (data1) {
				setSpId(data1.user._id);
				setActiveTab(TABS.ACCOUNT_DETAIL);
			} else {
				showNotification('error', error);
				throw new Error('Unable to create user');
			}
		}
	};
	// This is a function that is used to Add  Additional-Info about employees
	const additionalInfo = async (values) => {
		const formData = new FormData();
		formData.append('id', spId);
		formData.append('gender', values.gender);
		formData.append('birthDate', values.birthDate);
		formData.append('subcity', values.subcity);
		formData.append('wereda', values.wereda);
		formData.append('street', values.street);
		formData.append('categoryIds[]', [categorySelectedId]);
		for (let i = 0; i < subcategorySelectedId.length; i++)
			formData.append('subcategoryIds[]', subcategorySelectedId[i]);
		formData.append('workExperience', values.workExperience);
		formData.append('identificationCardType', values.personalId);
		formData.append('serviceProviderId', idImage[0]);
		formData.append('serviceProviderEducation', eduCertificateImage[0]);
		for (let i = 0; i < supCertificateImage.length; i++)
			formData.append('serviceProviderCertificate[]', supCertificateImage[i]);
		formData.append('educationalStatus', values.educationalStatus);
		for (let i = 0; i < servicesSelectedId.length; i++)
			formData.append('serviceIds[]', servicesSelectedId[i]);

		const [data, error] = await AddnewadditionalinfoRequest({}, formData);
		if (data) {
			setIsStepCompleted(true);
		} else {
			showNotification('error', error);
			throw new Error('Unable to create Service provider');
		}
		setButtondisable(false);
	};
	// This is a function that is used to Add Images of employees
	const Addprofileimagerequest = useRequest(
		tokenState,
		`/serviceprovider/updatespavatarbycallcenter/${spId}`,
		'patch',
	);
	// this is a function that is used to add-service-provider image
	const addProfilPicture = async () => {
		const formData = new FormData();
		formData.append('img', profilePicture);

		const [data, error] = await Addprofileimagerequest({}, formData);
		if (data) {
			showNotification('success', 'uploaded successfully');
		} else {
			showNotification('error', error);
		}
	};

	const handleSkip = () => {
		showNotification('success', 'Successfully created service provider');
		navigate(-1);
	};

	const handleWizardPagination = async (index) => {
		if (index === 0 && !spId) {
			// basic info
			await addBasicInfo(formik.values, formik.errors, formik.touched);
		} else if (index === 4 && !isStepCompleted) {
			// addition service profider info
			await additionalInfo(formik.values);
		} else if (index === 5) {
			// add profile image
			await addProfilPicture();
			handleSkip();
		}
	};

	return (
		<PageWrapper title='Wizard Edit'>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back
					</Button>
					<SubheaderSeparator />
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100 pb-3'>
					<div className='col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='AccountCircle'>
									<CardTitle>User Information</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<h3> Service Provider Information</h3>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>

					<div className='col-lg-8 col-md-6'>
						<Wizard
							isHeader
							stretch
							color='info'
							noValidate
							className='shadow-3d-info'
							isDisable={buttondisable}
							onSubmit={async (e) => {
								e.preventDefault();
								setButtondisable(true);
								/* eslint-disable-next-line no-unused-expressions */
								(await addBasicInfo(
									formik.values,
									formik.errors,
									formik.touched,
								)) && (await additionalInfo(formik.values));
								setButtondisable(false);
							}}
							onNext={handleWizardPagination}
							onSkip={handleSkip}>
							<WizardItem id='step0' title='Basic Information'>
								<Card
									isHeader
									stretch
									color='info'
									noValidate
									className='shadow-3d-info'>
									<CardHeader>
										<CardLabel icon='Edit' iconColor='warning'>
											<CardTitle>Personal Information</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody className='pt-0'>
										<div className='row g-4 m-2'>
											<div className='col-md-6'>
												<FormGroup
													id='firstName'
													label='First Name'
													isFloating>
													<Input
														placeholder='First Name'
														autoComplete='additional-name'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.firstName}
														isValid={formik.isValid}
														isTouched={formik.touched.firstName}
														invalidFeedback={formik.errors.firstName}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='lastName'
													label='Last Name'
													isFloating>
													<Input
														placeholder='Last Name'
														autoComplete='family-name'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.lastName}
														isValid={formik.isValid}
														isTouched={formik.touched.lastName}
														invalidFeedback={formik.errors.lastName}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='phoneNumber'
													label='Phone Number'
													isFloating>
													<Input
														placeholder='Phone Number'
														type='tel'
														autoComplete='tel'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.phoneNumber}
														isValid={formik.isValid}
														isTouched={formik.touched.phoneNumber}
														invalidFeedback={formik.errors.phoneNumber}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='emailAddress'
													label='Email address'
													isFloating>
													<Input
														type='email'
														placeholder='Email address'
														autoComplete='email'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.emailAddress}
														isTouched={formik.touched.emailAddress}
														invalidFeedback={formik.errors.emailAddress}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='location'
													label='Location'
													isFloating>
													<Input
														placeholder='location'
														autoComplete='location'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.location}
														isValid={formik.isValid}
														isTouched={formik.touched.location}
														invalidFeedback={formik.errors.location}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='promoCode'
													label='Promo Code'
													isFloating>
													<Input
														type='text'
														placeholder='Promo Code'
														autoComplete='promoCode'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.promoCode}
														isTouched={formik.touched.promoCode}
														invalidFeedback={formik.errors.promoCode}
														validFeedback='Looks good!'
														disabled={!!spId}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem id='step1' title='Account Detail'>
								<Card className='mb-0'>
									<CardHeader>
										<CardLabel icon='MarkunreadMailbox' iconColor='success'>
											<CardTitle>Additional Information</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody className='pt-0'>
										<div className='row g-4'>
											<div className='col-md-6'>
												<FormGroup id='gender' label='Gender' isFloating>
													<Select
														ariaLabel='Select'
														placeholder='Please select...'
														size='lg'
														value={formik.values.gender}
														onChange={formik.handleChange}
														disabled={isStepCompleted}>
														{gender.map((s) => (
															<Option key={s} value={s}>
																{s}
															</Option>
														))}
													</Select>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='birthDate'
													label='Birth Date'
													isFloating>
													<Input
														type='date'
														placeholder='Birth Date'
														autoComplete='birth-date'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.birthDate}
														isValid={formik.isValid}
														isTouched={formik.touched.birthDate}
														invalidFeedback={formik.errors.birthDate}
														validFeedback='Looks good!'
														disabled={isStepCompleted}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup id='wereda' label='Wereda' isFloating>
													<Input
														type='number'
														placeholder='Wereda'
														autoComplete='wereda'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.wereda}
														isValid={formik.isValid}
														isTouched={formik.touched.wereda}
														invalidFeedback={formik.errors.wereda}
														validFeedback='Looks good!'
														disabled={isStepCompleted}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup id='street' label='Street' isFloating>
													<Input
														type='text'
														placeholder='Street'
														autoComplete='Street'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.street}
														isValid={formik.isValid}
														isTouched={formik.touched.street}
														invalidFeedback={formik.errors.street}
														validFeedback='Looks good!'
														disabled={isStepCompleted}
													/>
												</FormGroup>
											</div>

											<div className='col-md-6'>
												<FormGroup
													id='educationalStatus'
													label='Educational Status'
													isFloating>
													<Select
														ariaLabel='Select'
														placeholder='Please select...'
														size='lg'
														value={formik.values.educationalStatus}
														onChange={formik.handleChange}
														disabled={isStepCompleted}>
														{education.map((s) => (
															<Option key={s} value={s}>
																{s}
															</Option>
														))}
													</Select>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='workExperience'
													label='Work Experience'
													isFloating>
													<Input
														type='number'
														placeholder='Work Experience'
														autoComplete='work-experience'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.workExperience}
														isValid={formik.isValid}
														isTouched={formik.touched.workExperience}
														invalidFeedback={
															formik.errors.workExperience
														}
														validFeedback='Looks good!'
														disabled={isStepCompleted}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup id='subcity' label='Sub-city'>
													<Select
														ariaLabel='Sub-city select'
														placeholder='Please select...'
														size='lg'
														value={formik.values.subcity}
														onChange={formik.handleChange}
														disabled={isStepCompleted}>
														{location.map((s) => (
															<Option key={s} value={s}>
																{s}
															</Option>
														))}
													</Select>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup id='category' label='Category'>
													<Select
														placeholder='Please select...'
														value={formik.values.category}
														onChange={(e) => {
															formik.handleChange(e);
															setCategorySelectedId(e.target.value);
														}}
														ariaLabel='Category select'
														disabled={isStepCompleted}>
														{categories
															? categories?.map((category) => (
																	<Option
																		key={category._id}
																		value={category._id}
																		id={category.name}>
																		{category.name}
																	</Option>
															  ))
															: []}
													</Select>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem id='step2' title='Subcategory'>
								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup>
											<Label>Subcategory</Label>
											<ChecksGroup>
												{subcategory.length > 0 ? (
													subcategory.map((cat) => (
														<Checks
															type='switch'
															key={cat._id}
															id={cat.name}
															name={cat._id}
															label={cat.name}
															value={cat._id}
															checked={subcategorySelectedId?.includes(
																cat._id,
															)}
															disabled={isStepCompleted}
															onChange={(e) => {
																if (e.target.checked) {
																	if (
																		subcategorySelectedId.length ===
																		3
																	) {
																		subcategorySelectedId.shift();
																		setSubcategorySelectedId([
																			...subcategorySelectedId,
																			e.target.value,
																		]);
																	} else {
																		setSubcategorySelectedId([
																			...subcategorySelectedId,
																			e.target.value,
																		]);
																	}
																} else {
																	setSubcategorySelectedId(
																		subcategorySelectedId.filter(
																			(id) =>
																				id !==
																				e.target.value,
																		),
																	);
																}
															}}
														/>
													))
												) : (
													<div className='text-center'>
														<h6>No subcategory to list</h6>
													</div>
												)}
											</ChecksGroup>
										</FormGroup>
									</div>
								</div>
							</WizardItem>
							<WizardItem id='step3' title='Service'>
								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup>
											<Label>Service</Label>

											{services.length > 0 ? (
												<ChecksGroup>
													{services.map((cat) => (
														<Checks
															type='switch'
															key={cat._id}
															id={cat.name}
															name='category'
															label={cat.name}
															value={cat._id}
															checked={servicesSelectedId.includes(
																cat._id,
															)}
															disabled={isStepCompleted}
															onChange={(e) => {
																if (e.target.checked) {
																	setServicesSelectedId([
																		...servicesSelectedId,
																		e.target.value,
																	]);
																} else {
																	setServicesSelectedId(
																		servicesSelectedId.filter(
																			(id) =>
																				id !==
																				e.target.value,
																		),
																	);
																}
															}}
														/>
													))}
												</ChecksGroup>
											) : (
												<div className='text-center'>
													<h6>No service to list</h6>
												</div>
											)}
										</FormGroup>
									</div>
								</div>
							</WizardItem>
							<WizardItem id='step4' title='Subcategory'>
								<div className='row g-4'>
									<h3>File uploads</h3>
								</div>
								<div className='row'>
									<div className='col-12'>
										<h4>Personal Id</h4>
										<div className='col-6 m-1'>
											<FormGroup id='personalId' label='Personal ID'>
												<Select
													ariaLabel='Personal ID select'
													placeholder='Please select...'
													size='lg'
													value={formik.values.personalId}
													onChange={formik.handleChange}
													disabled={isStepCompleted}>
													{personalId.map((s) => (
														<Option key={s} value={s}>
															{s}
														</Option>
													))}
												</Select>
											</FormGroup>
										</div>
										<Card>
											<CardBody>
												<div className='row g-4 align-items-center'>
													<div className='col-xl'>
														<div className='row g-4'>
															<div className='col-auto'>
																<Input
																	type='file'
																	accept='.pdf, image/*'
																	autoComplete='photo'
																	id='id_image'
																	onChange={(e) => {
																		setIdImage(e.target.files);
																	}}
																	value={formik.values.image}
																/>
															</div>
															<div className='col-auto'>
																<Button
																	color='dark'
																	isLight
																	icon='Delete'
																	onClick={() => {
																		setIdImage([]);
																		document.getElementById(
																			'id_image',
																		).value = null;
																	}}>
																	Delete
																</Button>
															</div>
															<div className='col-12'>
																<p className='lead text-muted'>
																	Personal Id like national id,
																	drivers license or, passport.
																</p>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
									<div className='col-12'>
										<h4>Educational Status Certificates</h4>
										<Card>
											<CardBody>
												<div className='row g-4 align-items-center'>
													<div className='col-xl'>
														<div className='row g-4'>
															<div className='col-auto'>
																<Input
																	type='file'
																	accept='.pdf, image/*'
																	autoComplete='file'
																	id='edu_image'
																	disabled={isStepCompleted}
																	onChange={(e) => {
																		setEduCertificateImage(
																			e.target.files,
																		);
																	}}
																	value={formik.values.eduImage}
																/>
															</div>
															<div className='col-auto'>
																<Button
																	color='dark'
																	isLight
																	icon='Delete'
																	onClick={() => {
																		setEduCertificateImage([]);
																		document.getElementById(
																			'edu_image',
																		).value = null;
																	}}>
																	Delete
																</Button>
															</div>
															<div className='col-12'>
																<p className='lead text-muted'>
																	Educational Status Certificates
																	like diploma, degree, short
																	trainings
																</p>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
									<div className='col-12'>
										<h4>Supportive Certificates</h4>
										<Card>
											<CardBody>
												<div className='row g-4 align-items-center'>
													<div className='col-xl'>
														<div className='row g-4'>
															<div className='col-auto'>
																<Input
																	type='file'
																	accept='.pdf, image/*'
																	autoComplete='file'
																	id='sup_image'
																	multiple
																	onChange={(e) => {
																		setSupCertificateImage(
																			e.target.files,
																		);
																	}}
																	value={formik.values.supImage}
																	disabled={isStepCompleted}
																/>
															</div>
															<div className='col-auto'>
																<Button
																	color='dark'
																	isLight
																	icon='Delete'
																	onClick={() => {
																		// eslint-disable-next-line prettier/prettier
																		setSupCertificateImage([]);
																		document.getElementById(
																			'sup_image',
																		).value = null;
																	}}>
																	Delete
																</Button>
															</div>
															<div className='col-12'>
																<p className='lead text-muted'>
																	Supportive Documents like work
																	experiences.
																</p>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
							</WizardItem>
							<WizardItem id='step5' title='Add Profile Image'>
								<div className='col-12'>
									<h4>Profile Image</h4>
									<Card>
										<CardBody>
											<div className='row g-4 align-items-center'>
												<div className='col-xl'>
													<div className='row g-4'>
														<div className='col-auto'>
															<Input
																type='file'
																accept='.pdf, image/*'
																autoComplete='file'
																id='profile_image'
																onChange={(e) => {
																	setProfilePicture(
																		e.target.files[0],
																	);
																}}
															/>
														</div>
														<div className='col-auto'>
															<Button
																color='dark'
																isLight
																icon='Delete'
																onClick={() => {
																	setEduCertificateImage([]);
																	document.getElementById(
																		'profile_image',
																	).value = null;
																}}>
																Delete
															</Button>
														</div>
														<div className='col-12'>
															<p className='lead text-muted'>
																Profile image for public view.
															</p>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</WizardItem>
						</Wizard>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AddServiceProvider;
