import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 ? height * (2155 / 854) : width}
			height={width !== 2155 ? width * (854 / 2155) : height}
			// viewBox='0 0 2155 854'
			viewBox="0 0 480 242" 
			version="1.1" 
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		// xmlns:xlink="http://www.w3.org/1999/xlink">
        >
    <title>Alen 5 2</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD-Copy" transform="translate(-451.000000, -732.000000)" fill="#FFFFFF">
            <g id="Alen-5" transform="translate(451.000000, 732.000000)">
                <g id="Home-Solution" transform="translate(12.899503, 189.772727)" fillRule="nonzero">
                    <polygon id="Path" points="38.4854403 51.1363636 30.0958807 51.1363636 30.0958807 29.5965021 8.42285156 29.5965021 8.42285156 51.1363636 0 51.1363636 0 2.66335227 8.42285156 2.66335227 8.42285156 22.8382457 30.0958807 22.8382457 30.0958807 2.66335227 38.4854403 2.66335227"/>
                    <path d="M47.051669,32.7925249 C47.051669,29.2635831 47.750799,26.0842063 49.1490589,23.2543945 C50.5473189,20.4245827 52.5115412,18.2495117 55.0417259,16.7291815 C57.5719105,15.2088512 60.4794034,14.4486861 63.7642045,14.4486861 C68.6248224,14.4486861 72.569913,16.0134055 75.5994762,19.1428445 C78.6290394,22.2722834 80.2658913,26.422674 80.510032,31.5940163 L80.5433239,33.4916548 C80.5433239,37.0427912 79.8608398,40.2166193 78.4958718,43.0131392 C77.1309038,45.8096591 75.1777788,47.9736328 72.6364968,49.5050604 C70.0952148,51.0364879 67.1599787,51.8022017 63.8307884,51.8022017 C58.7482244,51.8022017 54.6810636,50.1098633 51.6293058,46.7251864 C48.5775479,43.3405096 47.051669,38.8294567 47.051669,33.1920277 L47.051669,32.7925249 Z M55.1416016,33.4916548 C55.1416016,37.1981534 55.9073153,40.1000977 57.4387429,42.1974876 C58.9701705,44.2948775 61.1008523,45.3435724 63.8307884,45.3435724 C66.5607244,45.3435724 68.6858576,44.2782315 70.2061879,42.1475497 C71.7265181,40.0168679 72.4866832,36.8985263 72.4866832,32.7925249 C72.4866832,29.1526101 71.7043235,26.2673118 70.139604,24.13663 C68.5748846,22.0059482 66.4497514,20.9406072 63.7642045,20.9406072 C61.1230469,20.9406072 59.025657,21.9893022 57.4720348,24.0866921 C55.9184126,26.184082 55.1416016,29.3190696 55.1416016,33.4916548 Z" id="Shape"/>
                    <path d="M95.9011009,15.1145241 L96.1341442,18.8765092 C98.6643288,15.9246271 102.126687,14.4486861 106.521218,14.4486861 C111.337447,14.4486861 114.633345,16.2908381 116.408913,19.975142 C119.027876,16.2908381 122.71218,14.4486861 127.461825,14.4486861 C131.434659,14.4486861 134.39209,15.5473189 136.334118,17.7445845 C138.276145,19.9418501 139.269354,23.1822621 139.313743,27.4658203 L139.313743,51.1363636 L131.22381,51.1363636 L131.22381,27.6988636 C131.22381,25.4128196 130.724432,23.7371271 129.725675,22.6717862 C128.726918,21.6064453 127.07342,21.0737749 124.765181,21.0737749 C122.923029,21.0737749 121.419345,21.5676048 120.254128,22.5552646 C119.088912,23.5429244 118.27326,24.8357599 117.807173,26.4337713 L117.840465,51.1363636 L109.750533,51.1363636 L109.750533,27.4325284 C109.63956,23.1933594 107.475586,21.0737749 103.258612,21.0737749 C100.0182,21.0737749 97.7210582,22.3943537 96.3671875,25.0355114 L96.3671875,51.1363636 L88.277255,51.1363636 L88.277255,15.1145241 L95.9011009,15.1145241 Z" id="Path"/>
                    <path d="M164.392756,51.8022017 C159.265803,51.8022017 155.109863,50.1875444 151.924938,46.9582298 C148.740012,43.7289151 147.14755,39.4287109 147.14755,34.0576172 L147.14755,33.0588601 C147.14755,29.4633345 147.841131,26.2506658 149.228294,23.420854 C150.615456,20.5910423 152.563033,18.388228 155.071023,16.8124112 C157.579013,15.2365945 160.375533,14.4486861 163.460582,14.4486861 C168.365589,14.4486861 172.155318,16.0134055 174.829767,19.1428445 C177.504217,22.2722834 178.841442,26.7001065 178.841442,32.4263139 L178.841442,35.6889205 L155.304066,35.6889205 C155.548207,38.6629972 156.541415,41.015625 158.283691,42.746804 C160.025968,44.477983 162.217685,45.3435724 164.858842,45.3435724 C168.565341,45.3435724 171.583807,43.8454368 173.91424,40.8491655 L178.275479,45.0106534 C176.83283,47.1635298 174.907449,48.8336737 172.499334,50.0210849 C170.09122,51.2084961 167.389027,51.8022017 164.392756,51.8022017 Z M163.42729,20.9406072 C161.20783,20.9406072 159.415616,21.7174183 158.050648,23.2710405 C156.68568,24.8246626 155.814542,26.9886364 155.437234,29.7629616 L170.851385,29.7629616 L170.851385,29.1637074 C170.673828,26.4559659 169.952504,24.4085138 168.687411,23.0213512 C167.422319,21.6341886 165.668945,20.9406072 163.42729,20.9406072 Z" id="Shape"/>
                    <path d="M230.031516,38.6518999 C230.031516,36.521218 229.282449,34.8788175 227.784313,33.7246982 C226.286177,32.5705788 223.583984,31.4053622 219.677734,30.2290483 C215.771484,29.0527344 212.66424,27.7432528 210.356001,26.3006037 C205.939276,23.5262784 203.730913,19.9085582 203.730913,15.4474432 C203.730913,11.5411932 205.323375,8.32297585 208.508301,5.79279119 C211.693226,3.26260653 215.826971,1.9975142 220.909535,1.9975142 C224.283114,1.9975142 227.290483,2.61896307 229.931641,3.8618608 C232.572798,5.10475852 234.647994,6.87477805 236.157227,9.17191939 C237.66646,11.4690607 238.421076,14.0158913 238.421076,16.8124112 L230.031516,16.8124112 C230.031516,14.2822266 229.238059,12.3013583 227.651145,10.8698065 C226.064231,9.43825462 223.794833,8.72247869 220.842951,8.72247869 C218.09082,8.72247869 215.95459,9.31063565 214.43426,10.4869496 C212.913929,11.6632635 212.153764,13.3056641 212.153764,15.4141513 C212.153764,17.1897195 212.974964,18.6712092 214.617365,19.8586204 C216.259766,21.0460316 218.967507,22.2001509 222.740589,23.3209783 C226.513672,24.4418058 229.543235,25.7179954 231.829279,27.1495472 C234.115323,28.5810991 235.791016,30.2234996 236.856357,32.0767489 C237.921697,33.9299982 238.454368,36.0995206 238.454368,38.5853161 C238.454368,42.6247337 236.906294,45.8374023 233.810147,48.2233221 C230.714,50.6092418 226.513672,51.8022017 221.209162,51.8022017 C217.702415,51.8022017 214.478649,51.1530096 211.537864,49.8546254 C208.597079,48.5562411 206.311035,46.764027 204.679732,44.477983 C203.048429,42.1919389 202.232777,39.5285866 202.232777,36.4879261 L210.655629,36.4879261 C210.655629,39.2400568 211.565607,41.3707386 213.385565,42.8799716 C215.205522,44.3892045 217.813388,45.143821 221.209162,45.143821 C224.138849,45.143821 226.341664,44.5501154 227.817605,43.3627042 C229.293546,42.175293 230.031516,40.6050249 230.031516,38.6518999 Z" id="Path"/>
                    <path d="M244.45712,32.7925249 C244.45712,29.2635831 245.15625,26.0842063 246.55451,23.2543945 C247.95277,20.4245827 249.916992,18.2495117 252.447177,16.7291815 C254.977362,15.2088512 257.884854,14.4486861 261.169656,14.4486861 C266.030273,14.4486861 269.975364,16.0134055 273.004927,19.1428445 C276.03449,22.2722834 277.671342,26.422674 277.915483,31.5940163 L277.948775,33.4916548 C277.948775,37.0427912 277.266291,40.2166193 275.901323,43.0131392 C274.536355,45.8096591 272.58323,47.9736328 270.041948,49.5050604 C267.500666,51.0364879 264.56543,51.8022017 261.236239,51.8022017 C256.153675,51.8022017 252.086515,50.1098633 249.034757,46.7251864 C245.982999,43.3405096 244.45712,38.8294567 244.45712,33.1920277 L244.45712,32.7925249 Z M252.547053,33.4916548 C252.547053,37.1981534 253.312766,40.1000977 254.844194,42.1974876 C256.375621,44.2948775 258.506303,45.3435724 261.236239,45.3435724 C263.966175,45.3435724 266.091309,44.2782315 267.611639,42.1475497 C269.131969,40.0168679 269.892134,36.8985263 269.892134,32.7925249 C269.892134,29.1526101 269.109775,26.2673118 267.545055,24.13663 C265.980336,22.0059482 263.855202,20.9406072 261.169656,20.9406072 C258.528498,20.9406072 256.431108,21.9893022 254.877486,24.0866921 C253.323864,26.184082 252.547053,29.3190696 252.547053,33.4916548 Z" id="Shape"/>
                    <polygon id="Path" points="294.305309 51.1363636 286.215376 51.1363636 286.215376 0 294.305309 0"/>
                    <path d="M325.842951,47.6074219 C323.468129,50.4039418 320.094549,51.8022017 315.722212,51.8022017 C311.815962,51.8022017 308.858532,50.6591797 306.84992,48.3731357 C304.841309,46.0870916 303.837003,42.7800959 303.837003,38.4521484 L303.837003,15.1145241 L311.926935,15.1145241 L311.926935,38.3522727 C311.926935,42.9243608 313.824574,45.2104048 317.619851,45.2104048 C321.548295,45.2104048 324.20055,43.8010476 325.576616,40.9823331 L325.576616,15.1145241 L333.666548,15.1145241 L333.666548,51.1363636 L326.042702,51.1363636 L325.842951,47.6074219 Z" id="Path"/>
                    <path d="M352.95277,6.35875355 L352.95277,15.1145241 L359.311523,15.1145241 L359.311523,21.1070668 L352.95277,21.1070668 L352.95277,41.2153764 C352.95277,42.5914418 353.224654,43.5846502 353.768422,44.1950018 C354.312189,44.8053533 355.283203,45.1105291 356.681463,45.1105291 C357.613636,45.1105291 358.556907,44.9995561 359.511275,44.7776101 L359.511275,51.0364879 C357.669123,51.5469638 355.893555,51.8022017 354.18457,51.8022017 C347.970082,51.8022017 344.862837,48.3731357 344.862837,41.5150036 L344.862837,21.1070668 L338.936879,21.1070668 L338.936879,15.1145241 L344.862837,15.1145241 L344.862837,6.35875355 L352.95277,6.35875355 Z" id="Path"/>
                    <path d="M375.002219,51.1363636 L366.912287,51.1363636 L366.912287,15.1145241 L375.002219,15.1145241 L375.002219,51.1363636 Z M366.412908,5.75949929 C366.412908,4.51660156 366.806863,3.48455256 367.594771,2.66335227 C368.382679,1.84215199 369.509055,1.43155185 370.973899,1.43155185 C372.438743,1.43155185 373.570668,1.84215199 374.369673,2.66335227 C375.168679,3.48455256 375.568182,4.51660156 375.568182,5.75949929 C375.568182,6.98020241 375.168679,7.99560547 374.369673,8.80570845 C373.570668,9.61581143 372.438743,10.0208629 370.973899,10.0208629 C369.509055,10.0208629 368.382679,9.61581143 367.594771,8.80570845 C366.806863,7.99560547 366.412908,6.98020241 366.412908,5.75949929 Z" id="Shape"/>
                    <path d="M383.202237,32.7925249 C383.202237,29.2635831 383.901367,26.0842063 385.299627,23.2543945 C386.697887,20.4245827 388.662109,18.2495117 391.192294,16.7291815 C393.722479,15.2088512 396.629972,14.4486861 399.914773,14.4486861 C404.775391,14.4486861 408.720481,16.0134055 411.750044,19.1428445 C414.779608,22.2722834 416.41646,26.422674 416.6606,31.5940163 L416.693892,33.4916548 C416.693892,37.0427912 416.011408,40.2166193 414.64644,43.0131392 C413.281472,45.8096591 411.328347,47.9736328 408.787065,49.5050604 C406.245783,51.0364879 403.310547,51.8022017 399.981357,51.8022017 C394.898793,51.8022017 390.831632,50.1098633 387.779874,46.7251864 C384.728116,43.3405096 383.202237,38.8294567 383.202237,33.1920277 L383.202237,32.7925249 Z M391.29217,33.4916548 C391.29217,37.1981534 392.057884,40.1000977 393.589311,42.1974876 C395.120739,44.2948775 397.25142,45.3435724 399.981357,45.3435724 C402.711293,45.3435724 404.836426,44.2782315 406.356756,42.1475497 C407.877086,40.0168679 408.637251,36.8985263 408.637251,32.7925249 C408.637251,29.1526101 407.854892,26.2673118 406.290172,24.13663 C404.725453,22.0059482 402.60032,20.9406072 399.914773,20.9406072 C397.273615,20.9406072 395.176225,21.9893022 393.622603,24.0866921 C392.068981,26.184082 391.29217,29.3190696 391.29217,33.4916548 Z" id="Shape" />
                    <path d="M431.951793,15.1145241 L432.184837,19.2760121 C434.848189,16.0577947 438.343839,14.4486861 442.671786,14.4486861 C450.173562,14.4486861 453.991033,18.7433416 454.124201,27.3326527 L454.124201,51.1363636 L446.034268,51.1363636 L446.034268,27.7987393 C446.034268,25.5126953 445.540439,23.8203569 444.552779,22.7217241 C443.565119,21.6230913 441.950462,21.0737749 439.708807,21.0737749 C436.4462,21.0737749 434.015891,22.5497159 432.41788,25.501598 L432.41788,51.1363636 L424.327947,51.1363636 L424.327947,15.1145241 L431.951793,15.1145241 Z" id="Path"/>
                </g>
                <g id="Group-2">
                    <path d="M472.08,0 C476.454095,3.02763076e-14 480,3.54590478 480,7.92 L480,160.261818 C480,164.635913 476.454095,168.181818 472.08,168.181818 L7.92,168.181818 C3.54590478,168.181818 5.86474269e-15,164.635913 0,160.261818 L0,7.92 C-2.31202901e-15,3.54590478 3.54590478,-9.72848583e-16 7.92,0 L472.08,0 Z M108.514545,40.1560685 L82.8345455,40.1560685 L48.3945455,130.636068 L73.4745455,130.636068 L78.8745455,114.916068 L112.474545,114.916068 L117.874545,130.636068 L142.954545,130.636068 L108.514545,40.1560685 Z M190.430909,40.1560685 L166.910909,40.1560685 L166.910909,130.636068 L218.630909,130.636068 L218.630909,110.716068 L190.430909,110.716068 L190.430909,40.1560685 Z M295.867273,40.1560685 L244.387273,40.1560685 L244.387273,130.636068 L295.867273,130.636068 L295.867273,110.716068 L267.907273,110.716068 L267.907273,95.1160685 L294.307273,95.1160685 L294.307273,75.1960685 L267.907273,75.1960685 L267.907273,60.0760685 L295.867273,60.0760685 L295.867273,40.1560685 Z M349.943636,40.1560685 L326.423636,40.1560685 L326.423636,130.636068 L349.943636,130.636068 L349.943636,75.3160685 L393.383636,130.636068 L416.783636,130.636068 L416.783636,40.1560685 L393.383636,40.1560685 L393.383636,95.4760685 L349.943636,40.1560685 Z M95.6745455,66.9160685 L106.234545,97.0360685 L85.1145455,97.0360685 L95.6745455,66.9160685 Z" id="Combined-Shape"/>
                </g>
            </g>
        </g>
    </g>

		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
