import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';

import { useFormik } from 'formik';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Label from '../../components/bootstrap/forms/Label';

import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Icon from '../../components/icon/Icon';

import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import { useAppointmentContext } from '../../contexts/appointmentContext';
import { useAppContext } from '../../contexts/userContext';

const validate = (values) => {
	const errors = {};
	if (!values.reason) {
		errors.reason = 'Required';
	}
	return errors;
};
// eslint-disable-next-line react/prop-types
const UpcomingEvents = ({ isFluid, isDashboard, newBook }) => {
	const { tokenState } = useAppContext();
	// eslint-disable-next-line no-unused-vars
	const { appointments, setAppointments } = useAppointmentContext();

	// BEGIN :: Upcoming Events

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [filterMenu, setFilterMenu] = useState(false);
	const [demoData, setDemoData] = useState([]);
	let bookingList = [];

	const bookings = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/bookings`, {
				headers: {
					Authorization: `Bearer ${tokenState}`,
				},
			})
			.then((res) => {
				// eslint-disable-next-line no-plusplus
				for (let x = 0; x < res.data.data.bookings.length; x++) {
					// eslint-disable-next-line no-plusplus
					for (let y = 0; y < res.data.data.bookings[x]?.booking?.length; y++) {
						const z = {};
						z.bookedOn = res.data.data.bookings[x]?.updatedAt;
						z.bookedFor = res.data.data.bookings[x]?.startDate;
						z.bookedBy = res.data.data.bookings[x]?.bookedBy;
						z.endDate = res.data.data.bookings[x]?.endDate;
						z.callCenterName = res.data.data.bookings[x]?.callCenterName;
						z.booking = res.data.data.bookings[x]?.booking[y];
						bookingList.push(z);
					}
				}

				setDemoData([...bookingList]);

				setAppointments([...res.data.data.bookings]);
			})
			.catch(() => {
				// console.log(err.response.data.message);
			});
	};

	useEffect(() => {
		bookings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newBook]);

	const statusFormik = useFormik({
		initialValues: {
			status: '',
			reason: '',
			searchInput: '',
			statusInput: '',
			searchBy: '',
			eventStart: '',
			eventEnd: '',
			x: '',
		},
		validate,
	});

	bookingList = [];

	bookingList = demoData?.filter((data) =>
		data.booking?.status
			.toLowerCase()
			.includes(statusFormik.values.statusInput?.trim().toLowerCase()),
	);
	if (statusFormik.values.searchBy === 'Customer') {
		bookingList = bookingList?.filter(
			(data) =>
				data.booking.user?.firstName
					.toLowerCase()
					.includes(statusFormik.values.searchInput.toLowerCase()) ||
				data.booking.user?.lastName
					.toLowerCase()
					.includes(statusFormik.values.searchInput.toLowerCase()) ||
				`${data.booking.user?.firstName.trim()} ${data.booking.user?.lastName.trim()}`
					.toLowerCase()
					.trim()
					.includes(statusFormik.values.searchInput?.toLowerCase().trim()) ||
				data.booking.user?.phoneNumber.includes(statusFormik.values.searchInput),
		);
	} else {
		bookingList = bookingList?.filter(
			(data) =>
				data.booking.serviceProvider?.firstName
					.toLowerCase()
					.includes(statusFormik.values.searchInput?.toLowerCase()) ||
				data.booking.serviceProvider?.lastName
					.toLowerCase()
					.includes(statusFormik.values.searchInput?.toLowerCase()) ||
				`${data.booking.serviceProvider?.firstName.trim()} ${data.booking.serviceProvider?.lastName.trim()}`
					.trim()
					.toLowerCase()
					.includes(statusFormik.values.searchInput?.toLowerCase().trim()) ||
				data.booking.serviceProvider?.phoneNumber.includes(statusFormik.values.searchInput),
		);
	}

	if (moment(statusFormik.values.eventStart).format('D') !== 'Invalid date') {
		bookingList = bookingList?.filter(
			(filteredData) =>
				moment(filteredData.bookedFor).diff(
					moment(statusFormik.values.eventStart),
					'days',
					true,
				) >= 0,
		);
	}

	if (moment(statusFormik.values.eventEnd).format('D') !== 'Invalid date') {
		bookingList = bookingList?.filter(
			(filteredData) =>
				moment(filteredData?.bookedFor).diff(
					moment(statusFormik.values.eventEnd),
					'days',
				) <= 0,
			true,
		);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		const totalItems = bookingList.length;
		const totalPage = Math.ceil(totalItems / perPage);
		if (currentPage > totalPage) {
			setCurrentPage(1);
		}
	});

	return (
		<div style={{ width: '100%' }}>
			<Card>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle>Upcoming Appointments</CardTitle>
					</CardLabel>
				</CardHeader>
				{!isDashboard ? (
					<SubHeader className='w-100 m-0'>
						<SubHeaderLeft>
							<label
								className='border-0 bg-transparent cursor-pointer me-0'
								htmlFor='searchInput'>
								<Icon icon='Search' size='2x' color='primary' />
							</label>
							<Input
								id='searchInput'
								type='search'
								className='border-0 shadow-none bg-transparent'
								placeholder='Search...'
								onChange={statusFormik.handleChange}
								value={statusFormik.values.searchInput}
							/>
						</SubHeaderLeft>
						<SubHeaderRight>
							<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
								<DropdownToggle hasIcon={false}>
									<Button icon='FilterAlt' color='primary' isLight />
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<div className='container py-2'>
										<form
											className='row g-3'
											onSubmit={statusFormik.handleSubmit}>
											<div className='col-12'>
												<FormGroup>
													<Label htmlFor='statusInput'>
														Service Status
													</Label>
													<Input
														id='statusInput'
														name='statusInput'
														ariaLabel='name'
														placeholder='Service Status'
														list={[
															'Done',
															'Active',
															'Inprogress',
															'Assigned',
															'Pending',
															'Rejected',
														]}
														onChange={statusFormik.handleChange}
														value={statusFormik.values.statusInput}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup>
													<Label htmlFor='Search By'>Search By</Label>
													<Input
														id='searchBy'
														name='searchBy'
														ariaLabel='name'
														placeholder='search by'
														list={['Service provider', 'Customer']}
														onChange={statusFormik.handleChange}
														value={statusFormik.values.searchBy}
													/>
												</FormGroup>
											</div>
											<div className='row g-3'>
												<Label htmlFor='filterByDate'>Filter by date</Label>
												<div className='col-12'>
													<FormGroup id='eventStart' label='From'>
														<Input
															type='date'
															value={moment(
																statusFormik.values.eventStart,
															)
																.format(moment.HTML5_FMT.DATE)
																.toString()}
															onChange={statusFormik.handleChange}
														/>
													</FormGroup>
												</div>

												{statusFormik.values.eventStart.length > 0 && (
													<div className='col-12'>
														<FormGroup id='eventEnd' label='Up to'>
															<Input
																type='date'
																value={moment(
																	statusFormik.values.eventEnd,
																)
																	.format(moment.HTML5_FMT.DATE)
																	.toString()}
																onChange={statusFormik.handleChange}
																min={
																	statusFormik.values.eventStart
																		.toString()
																		.split('T')[0]
																}
															/>
														</FormGroup>
													</div>
												)}
											</div>
											<div className='col-6'>
												<Button
													color='primary'
													isOutline
													className='w-100'
													onClick={() => {
														statusFormik.setFieldValue(
															'statusInput',
															'',
														);
														statusFormik.setFieldValue('searchBy', '');
														statusFormik.setFieldValue(
															'eventStart',
															'',
														);
														statusFormik.setFieldValue('eventEnd', '');
													}}>
													Reset
												</Button>
											</div>
										</form>
									</div>
								</DropdownMenu>
							</Dropdown>
						</SubHeaderRight>
					</SubHeader>
				) : null}

				<CardBody className='table-responsive' isScrollable={isFluid}>
					{bookingList.length > 0 && (
						<ReactHTMLTableToExcel
							id='tests-table-xls-button'
							className='btn btn-info ml-5 mb-5'
							table='upcoming-appointment-table-to-xls'
							filename='Appointment List xls'
							sheet='tablexls'
							buttonText='Export '
						/>
					)}
					<table id='upcoming-appointment-table-to-xls' className='table table-modern'>
						<thead>
							<tr>
								<th>BookedOn </th>
								<th>BookedFor </th>
								<th>BookedBy</th>
								<th>Customer</th>
								<th>Service provider</th>
								<th>Service</th>
								<th>Payment</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(bookingList, currentPage, perPage).map(
								(item, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<tr key={index}>
										<td>
											<div className='d-flex align-items-center'>
												<span className='text-nowrap'>
													{item.bookedOn?.toString().slice('T', 10)}
												</span>
											</div>
										</td>
										<td>
											<div className='d-flex align-items-center'>
												<span className='text-nowrap'>
													{item.bookedFor?.toString().slice('T', 10)}
												</span>
											</div>
										</td>
										<td>
											<div>
												<div>{item?.callCenterName}</div>
												<div className='small text-muted'>
													{item.bookedBy}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
													{`${item.booking?.user?.firstName} ${item.booking?.user?.lastName}`}
												</div>
											</div>
										</td>
										<td>
											<div className='d-flex'>
												{item?.booking?.serviceProvider ? (
													<div className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'>
														{`${item?.booking?.serviceProvider?.firstName} ${item?.booking?.serviceProvider?.lastName}`}
													</div>
												) : (
													<div
														className='flex-grow-1 ms-3 d-flex align-items-center text-nowrap'
														style={{ color: 'red' }}>
														Not Assigned
													</div>
												)}
											</div>
										</td>
										<td>{item?.booking?.serviceName}</td>
										{/* eslint-disable-next-line no-unsafe-optional-chaining */}
										<td>{item?.booking.basePrice * item?.booking.quantity}</td>
										<td>{item?.booking.status}</td>
									</tr>
								),
							)}
						</tbody>
					</table>
				</CardBody>
				<PaginationButtons
					data={bookingList}
					label='events'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>
		</div>
	);
};

export default React.memo(UpcomingEvents);
